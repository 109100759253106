<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text="提交中"></loading>
    <el-form ref="form" :model="admin"  label-width="100px">
      <el-form-item label="用户名"  :required="true">
        <el-input type="text"  v-model="admin.username" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="所属角色"  :required="true" >
        <el-select  v-model="admin.roleId" placeholder="请选择" style="width: 100%">
          <el-option
              v-for="role in roles"
              :key="role.id"
              :label="role.name"
              :value="role.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码"  :required="true" v-show="this.id == ''">
        <el-input type="text"  v-model="admin.password" placeholder="6位以上包含字母、数字、特殊字符"></el-input>
      </el-form-item>
      <el-form-item label="姓名"  :required="true">
        <el-input type="text"  v-model="admin.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="性别"  :required="true">
        <el-radio v-model="admin.gender" :label="1">男</el-radio>
        <el-radio v-model="admin.gender" :label="2">女</el-radio>
      </el-form-item>
      <el-form-item label="手机"  :required="true">
        <el-input type="number" v-model="admin.mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="Email"  :required="true">
        <el-input type="text" v-model="admin.email" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" :rows="4" v-model="admin.remark" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  TipEnum,
  UrlEnum,
  request,
  checkPassword,
} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "BranchSave",
  data() {
    return {
      loading: false,
      id:0,
      roles:[],
      branches:[],
      admin:{
        username:'',
        roleId:'',
        password:'',
        name:'',
        gender:1,
        mobile:'',
        email:'',
        remark:'',
        branchId:''
      }
    }
  },
  components: {Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id = request('id');
      this.initData();
    }
    this.getRoles();
  },
  methods:{
    submit:function (){
      if(this.admin.username == ''){
        this.showMsgError("请输入用户名");
        return;
      }
      if(this.admin.roleId == ''){
        this.showMsgError("请选择所属角色");
        return;
      }
      if(this.id == ''){
        if (this.admin.password.length < 6) {
          this.showMsgError("请输入6位以上密码");
          return;
        }
        if (!checkPassword(this.admin.password)) {
          this.showMsgError("密码中必须包含字母、数字、特殊字符");
          return;
        }
      }
      if(this.admin.name == ''){
        this.showMsgError("请输入姓名");
        return;
      }
      if(this.admin.mobile == ''){
        this.showMsgError("请输入手机号");
        return;
      }
      if(this.admin.email == ''){
        this.showMsgError("请输入Email");
        return;
      }
      this.loading = true;
      let url = UrlEnum.ADMINS;
      let method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.admin,
      })
          .then((res) => {
            this.loading=false;
            this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
            setTimeout(function () {
              window.parent.postMessage({
                msg: '',
                key: 'refresh'
              }, '*')
            }, 800);
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
    initData(){
      this.loading = true;
      this.$http({
        method: 'GET',
        url: UrlEnum.ADMINS + "/" + request('id'),
        data: '',
      })
          .then((res) => {
            this.loading = false;
            this.admin = res.data;
          })
          .catch((res) => {
            this.loading=false;
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    },
    getRoles(){
      this.$http({
        method: 'GET',
        url: UrlEnum.ROLES + '?page=1&size=100',
        data: '',
      })
          .then((res) => {
            this.roles = res.data.list;
          })
          .catch((res) => {
            if(res.response != null){
              const data = res.response.data;
              this.showMsgError(data.message);
            }
          });
    }
  }
}
</script>

<style scoped>
</style>
